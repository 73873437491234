import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import { Seo, Layout, OtherPageWrapper, SeoPage } from "components"

const Page = () => (
  <Layout>
    <Seo
      title="Best Pancakes in Newhall"
      description="Neighbarista makes some of the best pancakes you’ll ever try. They are crispy on the outside the pillow fluffy on the inside. We want to start your day right with our breakfast combo."
      keywords="best pancakes, best pancakes near me, best pancakes in Newhall, best pancakes in Santa Clarita"
    />
    <OtherPageWrapper title="Best Pancakes in Newhall" />
    <SeoPage
      title="Best Pancakes in Newhall"
      name="Cecilia Gonzalez"
      review="The pancakes at Neighbarista melt in your mouth, they're delicious! The staff was friendly and attentive. We will be going back."
      date="7-27-2021"
    >
      <StaticImage
        imgStyle={{ borderRadius: "20px" }}
        src="../images/best-pancakes.jpeg"
        alt="Best Pancakes in Newhall"
        placeholder="blurred"
      />
    </SeoPage>
  </Layout>
)

export default Page
